import * as d3 from 'd3';
import { RGBColor } from 'd3';
import { ComplexNumber } from './complex-number';

const gpu = new GPU()

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

const DPR = window.devicePixelRatio;

let canvas = document.querySelector('canvas')!;
canvas.height = HEIGHT * DPR;
canvas.width = WIDTH * DPR;

canvas.style.height = `${HEIGHT}px`;
canvas.style.width = `${WIDTH}px`;
const canvasContext = canvas.getContext('2d')!;

canvasContext.scale(DPR, DPR)
// let xScaleCanvas = d3.scaleLinear().domain([-0.3829104131548463, -0.38290537392790114]).range([0, WIDTH]);
// let yScaleCanvas = d3.scaleLinear().domain([-0.6503857921181297, -0.6503814101816556]).range([HEIGHT, 0]);

let xScaleCanvas = d3.scaleLinear().domain([-2, 2]).range([0, WIDTH]);
let yScaleCanvas = d3.scaleLinear().domain([-2, 2]).range([HEIGHT, 0]);

let zr = 0
let zi = 0

d3.select('svg')
    .attr('width', WIDTH)
    .attr('height', HEIGHT)
    .append('g')
    .attr('transform', `translate(0, ${HEIGHT / 2})`)
    .call(d3.axisBottom(xScaleCanvas));

d3.select('svg')
    .append('g')
    .attr('transform', `translate(${WIDTH / 2}, 0)`)
    .call(d3.axisLeft(yScaleCanvas));

// canvas.addEventListener('mousemove', e => {
//     const c = new ComplexNumber(xScaleCanvas.invert(e.offsetX), yScaleCanvas.invert(e.offsetY))
//     const z = new ComplexNumber(0, 0)


//     canvasContext.clearRect(0, 0, canvas.width, canvas.height);
//     iterate(z, c)
// })

// canvas.addEventListener('contextmenu', e => {
//     e.preventDefault()
//     zr = xScaleCanvas.invert(e.offsetX)
//     zi = yScaleCanvas.invert(e.offsetY)
//     console.log(zr, zi)
//     drawMandelbrot()
// })

const colorScale: RGBColor[] = [];

for (let i = 0; i < 100; i++) {
    colorScale.push(d3.rgb(d3.interpolateHcl('red', 'blue')(i / 8)));
}

window.q = xScaleCanvas;
const zoom = d3.zoom().on('zoom', function hi(e) {
    xScaleCanvas = e.transform.rescaleX(d3.scaleLinear().domain([-2.3, 2.3]).range([0, WIDTH]));
    yScaleCanvas = e.transform.rescaleY(d3.scaleLinear().domain([-2, 2]).range([HEIGHT, 0]));
    console.log(e);
    drawMandelbrot();
});

d3.select('body').call(zoom);



drawMandelbrot();

function iterate(z: ComplexNumber, c: ComplexNumber, steps = 0) {

    const [x, y] = z.value;

    canvasContext.fillStyle = '#f00';
    canvasContext.beginPath();
    const path = new Path2D();
    path.arc(xScaleCanvas(x), yScaleCanvas(y), 3, 0, Math.PI * 2);
    canvasContext.fill(path);

    if (steps > 200) return;

    const z1 = z.square().add(c);
    const [x1, y1] = z1.value;

    canvasContext.beginPath();
    canvasContext.moveTo(xScaleCanvas(x), yScaleCanvas(y));
    canvasContext.lineTo(xScaleCanvas(x1), yScaleCanvas(y1));
    canvasContext.stroke();

    iterate(z1, c, steps + 1);
}

function drawMandelbrot() {
    const image = new ImageData(canvas.width, canvas.height);

    const width = canvas.width;
    const height = canvas.height;

    const [xDomainMin, xDomainMax] = xScaleCanvas.domain();
    const [yDomainMin, yDomainMax] = yScaleCanvas.domain();

    const render = gpu.createKernel(function test(zr0, zi0, width, height, xDomainMin, xDomainMax, yDomainMin, yDomainMax) {
        let zr = zr0;
        let zi = zi0;
        let cr = (this.thread.x / width) * (xDomainMax - xDomainMin) + xDomainMin;
        let ci = (this.thread.y / height) * (yDomainMax - yDomainMin) + yDomainMin;

        const MAX_ITERATIONS = 300
        let iterations = 0

        for (let i = 0; i < MAX_ITERATIONS; i++) {
            if (Math.sqrt(zr ** 2 + zi ** 2) > 2) {
                break
            }

            let zr0 = zr ** 2 - zi ** 2;
            let zi0 = 2 * zr * zi;

            zr = zr0 + cr;
            zi = zi0 + ci;

            iterations = iterations + 1
        }

        if (iterations === MAX_ITERATIONS) {
            this.color(0, 0, 0);
        }
        else {
            // this.color(1, 1, 1);
            this.color(Math.sin(iterations / 50), Math.sin(iterations / 60), Math.sin(iterations / 40), 1);
        }
    }, { argumentTypes: { zr0: 'Float', zi0: 'Float', width: 'Integer', height: 'Integer', xDomainMin: 'Float', xDomainMax: 'Float', yDomainMin: 'Float', yDomainMax: 'Float' } })
        .setOutput([width, height])
        .setGraphical(true)
        // .setPrecision('single')
        .setTactic('precision');

    window.q = render
    render(zr, zi, width, height, xDomainMin, xDomainMax, yDomainMin, yDomainMax)

    image.data.set(render.getPixels())
    canvasContext.putImageData(image, 0, 0);

}

window.drawMandelbrot = drawMandelbrot;
window.d3 = d3;
